$warning-color: #ffffff;
$warning-bg: transparent;

.warning {
	position: relative;
	color: $color-dark;
	width: 94%;
	z-index: 35;
	margin: 0 auto;

	h3{
		color: inherit;
		font-size: rem(27px);
		font-weight: 400;
		margin: 0 0 .25em;
		letter-spacing: 0.5px;
	}

	.list__item{
		position: relative;
		color: $color-dark;
		width: 100%;
		&::before{
			display: block;
			content: url(/images/local/v-202410170829/warning-triangle.png);
			margin-bottom: 1.3rem;
			filter: brightness(0);
		}
	}
	&__description{
		display: -webkit-box;
		-webkit-line-clamp: 4;
		-webkit-box-orient: vertical;
		overflow: hidden;
		text-overflow: ellipsis;
		font-size: rem(13px);
		line-height: 1.95;
		margin: 30px 0 37px;
	}

	&__more{
		text-align: left;
	}
	@include link {
		color: $color-dark;
		text-decoration: none;
	}

	@include link-over {
		text-decoration: underline;
	}

	&__more-button{
		background-color: $color-secondary;
		padding: 0.8em 1.45em;
		display: inline-block;
		font-size: rem(15px);
		font-weight: 800;
		letter-spacing: 1px;
		transition: background-color .3s ease-in-out, color .3s ease-in-out;
		@include link-over(&) {
			background-color: $color-brand;
		}
	}

	@include link(".warning__more-button") {
		color: $color-light;
	}

	@include min(600px) {
		position: absolute;
		left: 3.6%;
		top: 2rem;
		z-index: 51;
		max-width: 330px;
		color: $warning-color;
		margin: 0;

		.list__item{
			color: $warning-color;
			&::before{
				filter: none;
			}
		}
		@include link {
			color: $warning-color;
		}
	}
	@include min(1024px) {
		top: 17%;
	}
}
