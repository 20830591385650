
.section {
	padding: 1rem 0;

	.element-type-heading {
		position: relative;
		font-size: rem(40px);
		color: $color-brand;
		text-align: center;
		margin-bottom: .75em;
		&::before{
			position: absolute;
			left: 50%;
			transform: translate(-50%, -50%);
			font-size: 11vw;
			opacity: .075;
			color: $color-brand;
			font-weight: 400;
			white-space: nowrap;
			z-index: -1;
			@include min(1360px){
				font-size: rem(150px);
			}
		}
	}
	@include min(600px){
		.element-type-heading{
			min-height: 80px;
			font-size: rem(50px);
		}
		//&.-homepage-events .element-type-heading::before{
		//	content: "Nejbližší události";
		//}
		//.news .element-type-heading::before{
		//	content: "Aktuálně";
		//}
	}

	@include min(800px) {
		padding: 2rem 0;
	}

	@include min($max-width) {
		padding: 4rem 0;
	}
}

.homepage-tiles{
	.list{
		@include grid(500px 2 1rem, 800px 4 3%);
		&__item{
			position: relative;
			padding: 11% 18%;
			background-color: $color-light;
			box-shadow: 0 10px 32px #9f9f9f;
			transition: color .3s ease-in-out;
			@include link-over(&){
				color: $color-light;
				.article-link__title{
					color: $color-light;
				}
				.article-link::before{
					height: 100%;
				}
			}
			@include min(800px){
				margin-bottom: 6%;
			}
		}
	}
	.article-link{
		z-index: 5;
		display: block;
		text-decoration: none;
		&::before{
			content: "";
			position: absolute;
			top: 0;
			left: 0;
			right: 0;
			height: 5px;
			background-color: $color-secondary;
			transition: height .3s ease-in-out;
		}
		&::after{
			position: absolute;
			content: "";
			@include fill();
			z-index: 20;
		}
		&__title{
			position: relative;
    		z-index: 22;
			font-size: rem(26px);
			font-family: $font-headings;
			line-height: 1.3;
			margin-bottom: 1.4rem;
			display: block;
		}
		&__description{
			position: relative;
			z-index: 5;
			font-size: rem(14px);
			line-height: 1.5;
			margin-bottom: 0;
		}
	}
	@include min(1024px){
		margin-top: -6.1rem;
	}
}
