.section.-introduction{
	padding-top: 0;
	padding-bottom: 0;
}

.introduction{
	padding: 4rem 0;
	.text-component{
		margin-bottom: 0;
	}
}
