
.figure {
   &__caption {
      padding: .725em 10px 1.5em;
		border-bottom: 1px #cccccc solid;

		&.-photo,
		&.-video,
		&.-illustration {
			padding-left: 30px;
			background: {
				color: transparent;
				repeat: no-repeat;
				position: 10px .72em;
				size: 20px auto;
			}

			@include min(800px) {
				padding-left: 50px;
				background-position: 10px .48em;
				background-size: auto;
			}
		}

		&.-photo {
			background-image: icon("camera", #ccc);
		}

		&.-video {
			background-image: icon("movie", #ccc);
		}

		&.-illustration {
			background-image: icon("picture", #ccc);
		}
	}
}
