.section.-homepage-events {
	.element-type-heading{
		margin-bottom: 0.45em;
	}
}

.homepage-events {
	.list {
		margin: 0;
		@include grid(500px 2 3%, 800px 4 3%);
	}
}

.homepage-event {
	position: relative;
	&__image {
		position: relative;
		margin: 0;
		overflow: hidden;
		height: 0;
		padding-bottom: 132%;


		&:empty {
			transition: background-color .4s ease-in-out;
			background: lightgray encode-svg('<svg version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" width="125px" fill="#fff" viewBox="0 0 1000 1000" enable-background="new 0 0 1000 1000" xml:space="preserve"><g><path d="M881.1,132.5H118.9C59,132.5,10,181.5,10,241.4v517.3c0,59.9,49,108.9,108.9,108.9h762.2c59.9,0,108.9-49,108.9-108.9V241.4C990,181.5,941,132.5,881.1,132.5z M949.2,747.3c0,54.9-24.5,79.4-79.4,79.4H130.3c-54.9,0-79.4-24.5-79.4-79.4V252.7c0-54.9,24.5-79.4,79.4-79.4h739.5c54.9,0,79.4,24.5,79.4,79.4V747.3z M316.3,418.3L418.3,500l265.4-224.6l204.2,183.8v306.3H112.1V581.7L316.3,418.3z M193.8,234.6c-45.1,0-81.7,36.6-81.7,81.7s36.6,81.7,81.7,81.7s81.7-36.6,81.7-81.7S238.9,234.6,193.8,234.6z"/></g></svg>') no-repeat center center;

			@include link-over(&) {
				background-color: gray;
			}
		}

		img {
			position: absolute;
			@include fill();
			width: 100%;
			height: 100%;
			object-fit: cover;
			object-position: center;
			transition: transform .4s;
		}
	}

	&__body {
		@include flex(df, aic);
		padding: 1rem 8%;
		gap: 1rem;
		transition: background-color .3s ease-in-out, color .3s ease-in-out;
	}

	&__date {
		position: relative;
		//transform: translateY(50%);
		z-index: 20;
		font-family: $font-headings;
		@include flex(df, fww, aic);
		font-size: rem(24px);
		font-weight: 400;
		line-height: 1.2;
		@include min(1360px){
			font-size: rem(40px);
		}

		&-fullday {
			padding: 1.25rem 2.75rem;
			@include flex(dif, fww, aic, jcc);
			margin: 0 auto 0;
			width: 100%;
			background-color: $color-light;
			color: $color-brand;
			box-shadow: 0 0 25px #4c4c4c;
			transition: background-color .3s ease-in-out, color .3s ease-in-out;
		}
	}

	&__link {
		margin-bottom: 0;

		@include link(&) {
			//color: $events-title-color;
		}
	}

	&__title {
		padding: 0.5rem 0;
		font-size: rem(18px);
		margin: 0 0 0;
		font-weight: 500;
		color: inherit;

		@include min(1024px) {
			font-size: rem(22px);
		}
	}
	&__description{
		margin: 0;
	}

	.event__status {
		padding: 0 6%;
	}

	&__types {
		@include flex(df, fww);
		span:not(:last-child){
			&::after{
				content: "|";
				margin: 0 .75rem;
			}
		}
	}

	&__type {
		display: inline-block;

		&__link {
			font-size: rem(14px);
			font-weight: 400;
			@include link(&){
				color: #616161;
			}
		}
	}
	@include link-over(&){
		.homepage-event__date-fullday,
		.homepage-event__body{
			background-color: $color-secondary;
			color: $color-light;
			@include link(){
				color: $color-light;
			}
		}
	}
}
