.tabs-sections{
	[role="tabsection"]{
		padding:0;
	}
}

.tabs {
	display: none;
	[role="tab"]{
		font-size: rem(34px);
		line-height: 1.1;
		font-family: $font-headings;
		font-weight: 600;
	}

	&[data-tabs] > p {
		display: none;
	}
}

.tablist[role="tablist"]{
	list-style-type: none;
	margin: 0 0 2.5rem 0;
	padding: 0;
	display: flex;
	justify-content: center;
	align-items: baseline;


	.tablist__item {
		padding: 0 1rem;
		margin: 0;
		display: block;
		background-color: $tab-background;
		color: $tab-text;
		cursor: pointer;
		@include link-over(&){
			text-decoration: underline;
		}

		&:hover,
		&:focus {
			outline: 0;
		}

		&[aria-selected="true"] {
			cursor: default;
			font-size: rem(44px);
			background-color: $tab-selected-background;
			color: $tab-selected-text;
			font-weight: 700;
			text-decoration: none;
		}
	}
}
@include min(650px) {
	.tabs{
		display: block;
	}

	.tabs-sections [role="tabsection"]{
		h2.element-type-heading,
		h3.element-type-heading {
			@include min(651px) {
				position: absolute;
				left: -9999em;
				top: 0;
				width: 0;
				height: 0;
				line-height: 0;
				overflow: hidden;
				outline: 0;
			}
		}

		&[aria-hidden="true"] {
			display: none;
		}
	}
}
