.section.-abo {
	.centered {
		max-width: 780px;
	}
}

.abo {

	&__form {
		.form__section {

		}

		.label {
			position: absolute !important;
			left: -9999rem !important;
			top: auto;
			outline: 0;
			width: 0;
			height: 0;
			overflow: hidden;
		}

		.input {
			background-color: $color-light;
			border-color: $color-dark;
			color: $color-dark;
			min-height: 55px;
			box-shadow: 0 0 0;
			font-style: italic;
		}

		.gdpr-agreement {
			margin: 2.5rem 0 2.1rem;
		}
	}
}
